<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-card class="headerNav">
          <div slot="header" class="clearfix">
            <span class="title">问卷编辑</span>
          </div>
          <div class="setting">
            <div
              v-for="(item, index) in headerData"
              :key="item"
              class="setttingItem"
              :class="{ active: currentIndex == index }"
              @click="changeNav(index)"
            >
              {{ item }}
              <div class="space"></div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span class="title">{{ title }}</span>
          </div>
          <div v-if="currentIndex == 0">
            <el-form ref="form" :model="form" label-width="150px">
              <el-form-item label="当前试题数：">
                <el-input v-model="form.number" class="input-width" clearable disabled></el-input>

                <el-button type="primary" size="small" class="downBtn1" @click="remove"
                  >重置</el-button
                >
                <div class="templateBtn">
                  <el-upload
                    class="upload-demo"
                    action="none"
                    accept=".xls,.xlsx"
                    :http-request="add"
                    multiple
                    :file-list="fileList"
                    :limit="1"
                  >
                    <el-button type="primary" size="small">模板导入</el-button>
                  </el-upload>

                  <el-button size="medium" class="downBtn" @click="download">下载模板</el-button>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="medium" @click="handleSubmit">
                  保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="currentIndex !== 0">
            <el-form ref="form1" :model="form1" label-width="150px">
              <el-form-item label="开始时间：">
                <el-date-picker
                  v-model="form1.startTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间：">
                <el-date-picker
                  v-model="form1.endTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="显示设置：">
                <el-radio v-model="form1.showThankInfo" label="true">显示感谢信息</el-radio>
                <el-radio v-model="form1.showThankInfo" label="false">不显示感谢信息</el-radio>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="medium" @click="handleSubmit1()">
                  保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { download, addExcel, makeRules } from '@/api/examination'
import to from 'await-to'
export default {
  data() {
    return {
      headerData: ['问卷编辑设置', '问卷规则设置'],
      currentIndex: 0,
      title: '问卷编辑设置',
      fileList: [],
      form: {
        number: '',
      },
      form1: { surveyId: this.$route.query.id, showThankInfo: 'true', startTime: '', endTime: '' },
    }
  },
  created() {},
  methods: {
    //切换菜单
    changeNav(i) {
      if (i == 0) {
        this.title = '问卷编辑设置'
      } else {
        this.title = '问卷规则设置'
      }
      this.currentIndex = i
    },
    //时间转换
    selectTime(val) {
      this.form1.startTime = val[0]
      this.form1.endTime = val[1]
    },
    //下载模板
    async download() {
      const [res, err] = await to(download({ responseType: 'blob' }))
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '问卷模板.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    //模板导入
    async add(file) {
      const params = new FormData()
      params.append('file', file.file)
      params.append('surveyId', this.$route.query.id)
      const [res, err] = await to(addExcel(params))
      if (err) {
        this.fileList = []
        return this.$message.error(err.msg)
      }
      this.form.number = res.data
    },
    handleSubmit() {
      if (this.form.number == '')
        return this.$message({
          message: '请导入问卷模板',
          type: 'warning',
        })
      if (this.form.number == 0)
        return this.$message({
          message: '请输入问卷内容',
          type: 'warning',
        })
      this.currentIndex = 1
    },
    //提交保存
    async handleSubmit1() {
      if (this.form1.startTime == '')
        return this.$message({
          message: '请选择开始时间',
          type: 'warning',
        })
      if (this.form1.endTime == '')
        return this.$message({
          message: '请选择结束时间',
          type: 'warning',
        })
      const [, err] = await to(makeRules(this.form1))
      if (err) return this.$message.warning(err.msg)
      this.$message({
        message: '创建成功',
        type: 'success',
      })
      this.$router.push('/examination/quesManger')
    },
    remove() {
      ;(this.fileList = []), (this.form.number = 0)
    },
  },
}
</script>

<style scoped lang="scss">
.setting {
  cursor: pointer;
  font-size: 16px;
  .setttingItem {
    padding-left: 20px;
    line-height: 50px;
    position: relative;
    .space {
      position: absolute;
      left: 0;
      top: 13px;
      width: 4px;
      height: 24px;
      background-color: #ff7b33;
      display: none;
    }
  }
  .active {
    color: #ff7b33 !important;
    .space {
      display: block !important;
    }
  }
}
.input-width {
  width: 102px;
  height: 40px;
}
.templateBtn {
  display: flex;
  margin-top: 12px;
}
.downBtn {
  margin-left: 15px;
}
.downBtn1 {
  margin-left: 22px;
}
.span-box > i {
  margin: 0 5px;
}
.pass {
  margin-top: 24px;
  .passLine {
    margin-right: 13px;
  }
}
.writeNews {
  margin-left: 10px;
  width: 230px;
  border: 1px solid #c2c2c2;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  .header {
    display: flex;
    line-height: 40px;
    padding: 0 14px;
    border-bottom: 1px solid #e2e2e2;
    justify-content: space-between;
    .custom {
      color: #ff7b33;
    }
  }
  .checkList {
    padding: 20px;
  }
}
.text {
  line-height: 20px;
  font-size: 12px;
  margin-left: 100px;
  color: #909399;
}
.texts {
  margin-left: 128px;
}
.testTime {
  margin-left: 10px;
  .checkItem {
    width: 100%;
    padding: 15px 0;
    display: flex;
    align-items: center;
    .input-width,
    .date {
      margin-left: 16px;
      margin-right: 10px;
    }
    .icon {
      font-size: 14px;
      i {
        color: #b8b8b8;
      }
    }
  }
}
</style>
